/* roboto-100normal - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src:
    local('Roboto Thin '),
    local('Roboto-Thin'),
    url(/static/media/roboto-latin-100.987b8457.woff2) format('woff2'), 
    url(/static/media/roboto-latin-100.e9dbbe8a.woff) format('woff'); /* Modern Browsers */
}

/* roboto-100italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 100;
  src:
    local('Roboto Thin italic'),
    local('Roboto-Thinitalic'),
    url(/static/media/roboto-latin-100italic.6232f43d.woff2) format('woff2'), 
    url(/static/media/roboto-latin-100italic.d704bb3d.woff) format('woff'); /* Modern Browsers */
}

/* roboto-300normal - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Roboto Light '),
    local('Roboto-Light'),
    url(/static/media/roboto-latin-300.55536c8e.woff2) format('woff2'), 
    url(/static/media/roboto-latin-300.a1471d1d.woff) format('woff'); /* Modern Browsers */
}

/* roboto-300italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src:
    local('Roboto Light italic'),
    local('Roboto-Lightitalic'),
    url(/static/media/roboto-latin-300italic.d69924b9.woff2) format('woff2'), 
    url(/static/media/roboto-latin-300italic.210a7c78.woff) format('woff'); /* Modern Browsers */
}

/* roboto-400normal - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Roboto Regular '),
    local('Roboto-Regular'),
    url(/static/media/roboto-latin-400.5d4aeb4e.woff2) format('woff2'), 
    url(/static/media/roboto-latin-400.bafb105b.woff) format('woff'); /* Modern Browsers */
}

/* roboto-400italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local('Roboto Regular italic'),
    local('Roboto-Regularitalic'),
    url(/static/media/roboto-latin-400italic.d8bcbe72.woff2) format('woff2'), 
    url(/static/media/roboto-latin-400italic.9680d5a0.woff) format('woff'); /* Modern Browsers */
}

/* roboto-500normal - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src:
    local('Roboto Medium '),
    local('Roboto-Medium'),
    url(/static/media/roboto-latin-500.28546717.woff2) format('woff2'), 
    url(/static/media/roboto-latin-500.de8b7431.woff) format('woff'); /* Modern Browsers */
}

/* roboto-500italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src:
    local('Roboto Medium italic'),
    local('Roboto-Mediumitalic'),
    url(/static/media/roboto-latin-500italic.510dec37.woff2) format('woff2'), 
    url(/static/media/roboto-latin-500italic.ffcc050b.woff) format('woff'); /* Modern Browsers */
}

/* roboto-700normal - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Roboto Bold '),
    local('Roboto-Bold'),
    url(/static/media/roboto-latin-700.037d8304.woff2) format('woff2'), 
    url(/static/media/roboto-latin-700.cf6613d1.woff) format('woff'); /* Modern Browsers */
}

/* roboto-700italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local('Roboto Bold italic'),
    local('Roboto-Bolditalic'),
    url(/static/media/roboto-latin-700italic.010c1aee.woff2) format('woff2'), 
    url(/static/media/roboto-latin-700italic.846d1890.woff) format('woff'); /* Modern Browsers */
}

/* roboto-900normal - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src:
    local('Roboto Black '),
    local('Roboto-Black'),
    url(/static/media/roboto-latin-900.19b7a0ad.woff2) format('woff2'), 
    url(/static/media/roboto-latin-900.8c2ade50.woff) format('woff'); /* Modern Browsers */
}

/* roboto-900italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 900;
  src:
    local('Roboto Black italic'),
    local('Roboto-Blackitalic'),
    url(/static/media/roboto-latin-900italic.7b770d6c.woff2) format('woff2'), 
    url(/static/media/roboto-latin-900italic.bc833e72.woff) format('woff'); /* Modern Browsers */
}


.Toastify__toast-container {
  z-index: 9999;
  -webkit-transform: translate3d(0, 0, 9999px);
  position: fixed;
  padding: 4px;
  width: 320px;
  box-sizing: border-box;
  color: #fff; }
  .Toastify__toast-container--top-left {
    top: 1em;
    left: 1em; }
  .Toastify__toast-container--top-center {
    top: 1em;
    left: 50%;
    margin-left: -160px; }
  .Toastify__toast-container--top-right {
    top: 1em;
    right: 1em; }
  .Toastify__toast-container--bottom-left {
    bottom: 1em;
    left: 1em; }
  .Toastify__toast-container--bottom-center {
    bottom: 1em;
    left: 50%;
    margin-left: -160px; }
  .Toastify__toast-container--bottom-right {
    bottom: 1em;
    right: 1em; }

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 100vw;
    padding: 0;
    left: 0;
    margin: 0; }
    .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
      top: 0; }
    .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
      bottom: 0; }
    .Toastify__toast-container--rtl {
      right: 0;
      left: initial; } }

.Toastify__toast {
  position: relative;
  min-height: 64px;
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: 1px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  max-height: 800px;
  overflow: hidden;
  font-family: sans-serif;
  cursor: pointer;
  direction: ltr; }
  .Toastify__toast--rtl {
    direction: rtl; }
  .Toastify__toast--default {
    background: #fff;
    color: #aaa; }
  .Toastify__toast--info {
    background: #3498db; }
  .Toastify__toast--success {
    background: #07bc0c; }
  .Toastify__toast--warning {
    background: #f1c40f; }
  .Toastify__toast--error {
    background: #e74c3c; }
  .Toastify__toast-body {
    margin: auto 0;
    flex: 1 1; }

@media only screen and (max-width: 480px) {
  .Toastify__toast {
    margin-bottom: 0; } }

.Toastify__close-button {
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s ease;
  align-self: flex-start; }
  .Toastify__close-button--default {
    color: #000;
    opacity: 0.3; }
  .Toastify__close-button:hover, .Toastify__close-button:focus {
    opacity: 1; }

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1); }
  100% {
    transform: scaleX(0); } }

.Toastify__progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  z-index: 9999;
  opacity: 0.7;
  background-color: rgba(255, 255, 255, 0.7);
  transform-origin: left; }
  .Toastify__progress-bar--animated {
    animation: Toastify__trackProgress linear 1 forwards; }
  .Toastify__progress-bar--controlled {
    transition: transform .2s; }
  .Toastify__progress-bar--rtl {
    right: 0;
    left: initial;
    transform-origin: right; }
  .Toastify__progress-bar--default {
    background: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55); }

@keyframes Toastify__bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0); }
  75% {
    transform: translate3d(10px, 0, 0); }
  90% {
    transform: translate3d(-5px, 0, 0); }
  to {
    transform: none; } }

@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0); } }

@keyframes Toastify__bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0); }
  75% {
    transform: translate3d(-10px, 0, 0); }
  90% {
    transform: translate3d(5px, 0, 0); }
  to {
    transform: none; } }

@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0); }
  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0); } }

@keyframes Toastify__bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0); }
  75% {
    transform: translate3d(0, 10px, 0); }
  90% {
    transform: translate3d(0, -5px, 0); }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0); } }

@keyframes Toastify__bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0); }
  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0); }
  75% {
    transform: translate3d(0, -10px, 0); }
  90% {
    transform: translate3d(0, 5px, 0); }
  to {
    transform: none; } }

@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0); }
  40%,
  45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0); }
  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0); } }

.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft; }

.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight; }

.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown; }

.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp; }

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft; }

.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight; }

.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp; }

.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown; }

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

@keyframes Toastify__zoomOut {
  from {
    opacity: 1; }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); }
  to {
    opacity: 0; } }

.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn; }

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut; }

@keyframes Toastify__flipIn {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in; }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1; }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg); }
  to {
    transform: perspective(400px); } }

@keyframes Toastify__flipOut {
  from {
    transform: perspective(400px); }
  30% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1; }
  to {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0; } }

.Toastify__flip-enter {
  animation-name: Toastify__flipIn; }

.Toastify__flip-exit {
  animation-name: Toastify__flipOut; }

@keyframes Toastify__slideInRight {
  from {
    transform: translate3d(110%, 0, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes Toastify__slideInLeft {
  from {
    transform: translate3d(-110%, 0, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes Toastify__slideInUp {
  from {
    transform: translate3d(0, 110%, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes Toastify__slideInDown {
  from {
    transform: translate3d(0, -110%, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0); } }

@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0); } }

@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0); } }

@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0); } }

.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft; }

.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight; }

.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown; }

.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp; }

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft; }

.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight; }

.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp; }

.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown; }

/*# sourceMappingURL=ReactToastify.css.map */
.image-gallery-icon {
  color: #fff;
  transition: all .2s ease-out;
  -webkit-appearance: none;
          appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
  position: absolute;
  z-index: 4;
  filter: drop-shadow(0 2px 2px #1a1a1a); }
  @media (min-width: 768px) {
    .image-gallery-icon:hover {
      color: #337ab7; }
      .image-gallery-icon:hover .image-gallery-svg {
        transform: scale(1.1); } }
  .image-gallery-icon:focus {
    outline: 2px solid #337ab7; }

.image-gallery-using-mouse .image-gallery-icon:focus {
  outline: none; }

.image-gallery-fullscreen-button,
.image-gallery-play-button {
  bottom: 0;
  padding: 20px; }
  .image-gallery-fullscreen-button .image-gallery-svg,
  .image-gallery-play-button .image-gallery-svg {
    height: 36px;
    width: 36px; }
  @media (max-width: 768px) {
    .image-gallery-fullscreen-button,
    .image-gallery-play-button {
      padding: 15px; }
      .image-gallery-fullscreen-button .image-gallery-svg,
      .image-gallery-play-button .image-gallery-svg {
        height: 24px;
        width: 24px; } }
  @media (max-width: 480px) {
    .image-gallery-fullscreen-button,
    .image-gallery-play-button {
      padding: 10px; }
      .image-gallery-fullscreen-button .image-gallery-svg,
      .image-gallery-play-button .image-gallery-svg {
        height: 16px;
        width: 16px; } }

.image-gallery-fullscreen-button {
  right: 0; }

.image-gallery-play-button {
  left: 0; }

.image-gallery-left-nav,
.image-gallery-right-nav {
  padding: 50px 10px;
  top: 50%;
  transform: translateY(-50%); }
  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg {
    height: 120px;
    width: 60px; }
  @media (max-width: 768px) {
    .image-gallery-left-nav .image-gallery-svg,
    .image-gallery-right-nav .image-gallery-svg {
      height: 72px;
      width: 36px; } }
  @media (max-width: 480px) {
    .image-gallery-left-nav .image-gallery-svg,
    .image-gallery-right-nav .image-gallery-svg {
      height: 48px;
      width: 24px; } }
  .image-gallery-left-nav[disabled],
  .image-gallery-right-nav[disabled] {
    cursor: disabled;
    opacity: .6;
    pointer-events: none; }

.image-gallery-left-nav {
  left: 0; }

.image-gallery-right-nav {
  right: 0; }

.image-gallery {
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  position: relative; }
  .image-gallery.fullscreen-modal {
    background: #000;
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 5; }
    .image-gallery.fullscreen-modal .image-gallery-content {
      top: 50%;
      transform: translateY(-50%); }

.image-gallery-content {
  position: relative;
  line-height: 0;
  top: 0; }
  .image-gallery-content.fullscreen {
    background: #000; }
  .image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: calc(100vh - 80px); }
  .image-gallery-content.left .image-gallery-slide .image-gallery-image, .image-gallery-content.right .image-gallery-slide .image-gallery-image {
    max-height: 100vh; }

.image-gallery-slide-wrapper {
  position: relative; }
  .image-gallery-slide-wrapper.left, .image-gallery-slide-wrapper.right {
    display: inline-block;
    width: calc(100% - 110px); }
    @media (max-width: 768px) {
      .image-gallery-slide-wrapper.left, .image-gallery-slide-wrapper.right {
        width: calc(100% - 87px); } }
  .image-gallery-slide-wrapper.image-gallery-rtl {
    direction: rtl; }

.image-gallery-slides {
  line-height: 0;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  text-align: center; }

.image-gallery-slide {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%; }
  .image-gallery-slide.center {
    position: relative; }
  .image-gallery-slide .image-gallery-image {
    width: 100%;
    object-fit: contain; }
  .image-gallery-slide .image-gallery-description {
    background: rgba(0, 0, 0, 0.4);
    bottom: 70px;
    color: #fff;
    left: 0;
    line-height: 1;
    padding: 10px 20px;
    position: absolute;
    white-space: normal; }
    @media (max-width: 768px) {
      .image-gallery-slide .image-gallery-description {
        bottom: 45px;
        font-size: .8em;
        padding: 8px 15px; } }

.image-gallery-bullets {
  bottom: 20px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  width: 80%;
  z-index: 4; }
  .image-gallery-bullets .image-gallery-bullets-container {
    margin: 0;
    padding: 0;
    text-align: center; }
  .image-gallery-bullets .image-gallery-bullet {
    -webkit-appearance: none;
            appearance: none;
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 50%;
    box-shadow: 0 1px 0 #1a1a1a;
    cursor: pointer;
    display: inline-block;
    margin: 0 5px;
    outline: none;
    padding: 5px;
    transition: background .2s ease-out; }
    @media (max-width: 768px) {
      .image-gallery-bullets .image-gallery-bullet {
        margin: 0 3px;
        padding: 3px; } }
    @media (max-width: 480px) {
      .image-gallery-bullets .image-gallery-bullet {
        padding: 2.7px; } }
    .image-gallery-bullets .image-gallery-bullet:focus, .image-gallery-bullets .image-gallery-bullet:hover {
      background: #337ab7;
      transform: scale(1.1); }
    .image-gallery-bullets .image-gallery-bullet.active {
      background: #fff; }

.image-gallery-thumbnails-wrapper {
  position: relative; }
  .image-gallery-thumbnails-wrapper.thumbnails-wrapper-rtl {
    direction: rtl; }
  .image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
    display: inline-block;
    vertical-align: top;
    width: 100px; }
    @media (max-width: 768px) {
      .image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
        width: 81px; } }
    .image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails, .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails {
      height: 100%;
      width: 100%;
      left: 0;
      padding: 0;
      position: absolute;
      top: 0; }
      .image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail, .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail {
        display: block;
        margin-right: 0;
        padding: 0; }
        .image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail + .image-gallery-thumbnail, .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail + .image-gallery-thumbnail {
          margin-left: 0;
          margin-top: 2px; }
  .image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
    margin: 0 5px; }
    @media (max-width: 768px) {
      .image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
        margin: 0 3px; } }

.image-gallery-thumbnails {
  overflow: hidden;
  padding: 5px 0; }
  @media (max-width: 768px) {
    .image-gallery-thumbnails {
      padding: 3px 0; } }
  .image-gallery-thumbnails .image-gallery-thumbnails-container {
    cursor: pointer;
    text-align: center;
    transition: transform .45s ease-out;
    white-space: nowrap; }

.image-gallery-thumbnail {
  display: inline-block;
  border: 4px solid transparent;
  transition: border .3s ease-out;
  width: 100px;
  background: transparent;
  padding: 0; }
  @media (max-width: 768px) {
    .image-gallery-thumbnail {
      border: 3px solid transparent;
      width: 81px; } }
  .image-gallery-thumbnail + .image-gallery-thumbnail {
    margin-left: 2px; }
  .image-gallery-thumbnail .image-gallery-thumbnail-inner {
    position: relative; }
  .image-gallery-thumbnail .image-gallery-thumbnail-image {
    vertical-align: middle;
    width: 100%;
    line-height: 0; }
  .image-gallery-thumbnail.active, .image-gallery-thumbnail:hover, .image-gallery-thumbnail:focus {
    outline: none;
    border: 4px solid #337ab7; }
    @media (max-width: 768px) {
      .image-gallery-thumbnail.active, .image-gallery-thumbnail:hover, .image-gallery-thumbnail:focus {
        border: 3px solid #337ab7; } }

.image-gallery-thumbnail-label {
  box-sizing: border-box;
  color: white;
  font-size: 1em;
  left: 0;
  line-height: 1em;
  padding: 5%;
  position: absolute;
  top: 50%;
  text-shadow: 1px 1px 0 black;
  transform: translateY(-50%);
  white-space: normal;
  width: 100%; }
  @media (max-width: 768px) {
    .image-gallery-thumbnail-label {
      font-size: .8em;
      line-height: .8em; } }

.image-gallery-index {
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  line-height: 1;
  padding: 10px 20px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 4; }
  @media (max-width: 768px) {
    .image-gallery-index {
      font-size: .8em;
      padding: 5px 10px; } }

@-webkit-keyframes closeWindow {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes closeWindow {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.ril__outer {
  background-color: rgba(0, 0, 0, 0.85);
  outline: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  -ms-content-zooming: none;
  -ms-user-select: none;
  -ms-touch-select: none;
  -ms-touch-action: none;
      touch-action: none;
}

.ril__outerClosing {
  opacity: 0;
}

.ril__inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.ril__image,
.ril__imagePrev,
.ril__imageNext {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  max-width: none;
  -ms-content-zooming: none;
  -ms-user-select: none;
  -ms-touch-select: none;
  -ms-touch-action: none;
      touch-action: none;
}

.ril__imageDiscourager {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.ril__navButtons {
  border: none;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 20px;
  height: 34px;
  padding: 40px 30px;
  margin: auto;
  cursor: pointer;
  opacity: 0.7;
}
.ril__navButtons:hover {
  opacity: 1;
}
.ril__navButtons:active {
  opacity: 0.7;
}

.ril__navButtonPrev {
  left: 0;
  background: rgba(0, 0, 0, 0.2)
    url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDE5LDMgLTIsLTIgLTE2LDE2IDE2LDE2IDEsLTEgLTE1LC0xNSAxNSwtMTUgeiIgZmlsbD0iI0ZGRiIvPjwvc3ZnPg==')
    no-repeat center;
}

.ril__navButtonNext {
  right: 0;
  background: rgba(0, 0, 0, 0.2)
    url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDEsMyAyLC0yIDE2LDE2IC0xNiwxNiAtMSwtMSAxNSwtMTUgLTE1LC0xNSB6IiBmaWxsPSIjRkZGIi8+PC9zdmc+')
    no-repeat center;
}

.ril__downloadBlocker {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7');
  background-size: cover;
}

.ril__caption,
.ril__toolbar {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  right: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.ril__caption {
  bottom: 0;
  max-height: 150px;
  overflow: auto;
}

.ril__captionContent {
  padding: 10px 20px;
  color: #fff;
}

.ril__toolbar {
  top: 0;
  height: 50px;
}

.ril__toolbarSide {
  height: 50px;
  margin: 0;
}

.ril__toolbarLeftSide {
  padding-left: 20px;
  padding-right: 0;
  -ms-flex: 0 1 auto;
      flex: 0 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ril__toolbarRightSide {
  padding-left: 0;
  padding-right: 20px;
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
}

.ril__toolbarItem {
  display: inline-block;
  line-height: 50px;
  padding: 0;
  color: #fff;
  font-size: 120%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ril__toolbarItemChild {
  vertical-align: middle;
}

.ril__builtinButton {
  width: 40px;
  height: 35px;
  cursor: pointer;
  border: none;
  opacity: 0.7;
}
.ril__builtinButton:hover {
  opacity: 1;
}
.ril__builtinButton:active {
  outline: none;
}

.ril__builtinButtonDisabled {
  cursor: default;
  opacity: 0.5;
}
.ril__builtinButtonDisabled:hover {
  opacity: 0.5;
}

.ril__closeButton {
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIj48cGF0aCBkPSJtIDEsMyAxLjI1LC0xLjI1IDcuNSw3LjUgNy41LC03LjUgMS4yNSwxLjI1IC03LjUsNy41IDcuNSw3LjUgLTEuMjUsMS4yNSAtNy41LC03LjUgLTcuNSw3LjUgLTEuMjUsLTEuMjUgNy41LC03LjUgLTcuNSwtNy41IHoiIGZpbGw9IiNGRkYiLz48L3N2Zz4=')
    no-repeat center;
}

.ril__zoomInButton {
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PGcgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PHBhdGggZD0iTTEgMTlsNi02Ii8+PHBhdGggZD0iTTkgOGg2Ii8+PHBhdGggZD0iTTEyIDV2NiIvPjwvZz48Y2lyY2xlIGN4PSIxMiIgY3k9IjgiIHI9IjciIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIyIi8+PC9zdmc+')
    no-repeat center;
}

.ril__zoomOutButton {
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PGcgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PHBhdGggZD0iTTEgMTlsNi02Ii8+PHBhdGggZD0iTTkgOGg2Ii8+PC9nPjxjaXJjbGUgY3g9IjEyIiBjeT0iOCIgcj0iNyIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiLz48L3N2Zz4=')
    no-repeat center;
}

.ril__outerAnimating {
  -webkit-animation-name: closeWindow;
          animation-name: closeWindow;
}

@-webkit-keyframes pointFade {
  0%,
  19.999%,
  100% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
}

@keyframes pointFade {
  0%,
  19.999%,
  100% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
}

.ril__loadingCircle {
  width: 60px;
  height: 60px;
  position: relative;
}

.ril__loadingCirclePoint {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.ril__loadingCirclePoint::before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 11%;
  height: 30%;
  background-color: #fff;
  border-radius: 30%;
  -webkit-animation: pointFade 800ms infinite ease-in-out both;
          animation: pointFade 800ms infinite ease-in-out both;
}
.ril__loadingCirclePoint:nth-of-type(1) {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.ril__loadingCirclePoint:nth-of-type(7) {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.ril__loadingCirclePoint:nth-of-type(1)::before,
.ril__loadingCirclePoint:nth-of-type(7)::before {
  -webkit-animation-delay: -800ms;
          animation-delay: -800ms;
}
.ril__loadingCirclePoint:nth-of-type(2) {
  -webkit-transform: rotate(30deg);
          transform: rotate(30deg);
}
.ril__loadingCirclePoint:nth-of-type(8) {
  -webkit-transform: rotate(210deg);
          transform: rotate(210deg);
}
.ril__loadingCirclePoint:nth-of-type(2)::before,
.ril__loadingCirclePoint:nth-of-type(8)::before {
  -webkit-animation-delay: -666ms;
          animation-delay: -666ms;
}
.ril__loadingCirclePoint:nth-of-type(3) {
  -webkit-transform: rotate(60deg);
          transform: rotate(60deg);
}
.ril__loadingCirclePoint:nth-of-type(9) {
  -webkit-transform: rotate(240deg);
          transform: rotate(240deg);
}
.ril__loadingCirclePoint:nth-of-type(3)::before,
.ril__loadingCirclePoint:nth-of-type(9)::before {
  -webkit-animation-delay: -533ms;
          animation-delay: -533ms;
}
.ril__loadingCirclePoint:nth-of-type(4) {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.ril__loadingCirclePoint:nth-of-type(10) {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}
.ril__loadingCirclePoint:nth-of-type(4)::before,
.ril__loadingCirclePoint:nth-of-type(10)::before {
  -webkit-animation-delay: -400ms;
          animation-delay: -400ms;
}
.ril__loadingCirclePoint:nth-of-type(5) {
  -webkit-transform: rotate(120deg);
          transform: rotate(120deg);
}
.ril__loadingCirclePoint:nth-of-type(11) {
  -webkit-transform: rotate(300deg);
          transform: rotate(300deg);
}
.ril__loadingCirclePoint:nth-of-type(5)::before,
.ril__loadingCirclePoint:nth-of-type(11)::before {
  -webkit-animation-delay: -266ms;
          animation-delay: -266ms;
}
.ril__loadingCirclePoint:nth-of-type(6) {
  -webkit-transform: rotate(150deg);
          transform: rotate(150deg);
}
.ril__loadingCirclePoint:nth-of-type(12) {
  -webkit-transform: rotate(330deg);
          transform: rotate(330deg);
}
.ril__loadingCirclePoint:nth-of-type(6)::before,
.ril__loadingCirclePoint:nth-of-type(12)::before {
  -webkit-animation-delay: -133ms;
          animation-delay: -133ms;
}
.ril__loadingCirclePoint:nth-of-type(7) {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.ril__loadingCirclePoint:nth-of-type(13) {
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
}
.ril__loadingCirclePoint:nth-of-type(7)::before,
.ril__loadingCirclePoint:nth-of-type(13)::before {
  -webkit-animation-delay: 0ms;
          animation-delay: 0ms;
}

.ril__loadingContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.ril__imagePrev .ril__loadingContainer,
.ril__imageNext .ril__loadingContainer {
  display: none;
}

.ril__errorContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  color: #fff;
}
.ril__imagePrev .ril__errorContainer,
.ril__imageNext .ril__errorContainer {
  display: none;
}

.ril__loadingContainer__icon {
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
}

